import { HAWAIIAN, METERS, FEET } from '@surfline/web-common';
import { round } from 'lodash';
import type { SurfHeight } from 'types/units';

export const binMap: { [key: string]: Array<number> } = {
  FT: [6, 12, 20],
  HI: [6, 12, 20],
  M: [1.83, 3.66, 6.1],
};

export const getMaxWaveHeight = (maxHeight: number, units: string) => {
  const bins = binMap[units] || [];
  const digits = units === 'M' ? 1 : 0;
  return bins.find((bin: number) => maxHeight <= bin) || round(maxHeight, digits);
};

export type GraphYScale = 'exponent' | 'linear';

export const getGraphYScale = (maxHeight: number, units: SurfHeight) => {
  let yAxisScale: GraphYScale = 'linear';

  // set scale based on max wave height relative to the units
  switch (units) {
    case FEET:
      yAxisScale = maxHeight > 10 ? 'exponent' : 'linear';
      break;
    case METERS:
      yAxisScale = maxHeight > 3 ? 'exponent' : 'linear';
      break;
    case HAWAIIAN:
      yAxisScale = maxHeight > 5 ? 'exponent' : 'linear';
      break;
    default:
      break;
  }
  return yAxisScale;
};

export const scaleWaveHeight = (surfHeight: number, units: SurfHeight, maxPixels: number = 140) => {
  let multiplier = -0.085;
  if (units === 'M') {
    multiplier *= 3.28084;
  } else if (units === 'HI') {
    multiplier *= 2;
  }
  return -maxPixels * Math.exp(multiplier * surfHeight) + maxPixels;
};

export default scaleWaveHeight;
