import type { DaySelectorData } from 'types/daySelector';

import baseFetch, { createParamString } from '../baseFetch';

interface FetchForecastOutlookData {
  noCache?: string;
  startDate?: string | Date;
  units: { surfHeight?: string } | undefined;
}

interface FetchForecastOutlookOptions {
  method: string;
  headers: {
    Accept: string;
    'Content-Type': string;
    'Cache-Control'?: string;
  };
}

const fetchForecastOutlook = async (
  spotIds: Array<string>,
  data: FetchForecastOutlookData = {
    noCache: 'false',
    units: undefined,
  },
): Promise<DaySelectorData> => {
  let cacheEnabled = !!data.units?.surfHeight;

  const options: FetchForecastOutlookOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (data?.noCache?.toLowerCase() === 'true') {
    options.headers['Cache-Control'] = 'no-cache';
    cacheEnabled = false;
  }

  let url = `/kbyg/spots/forecasts-overview?${createParamString({
    cacheEnabled,
    'units[waveHeight]': data.units?.surfHeight?.toUpperCase() ?? null,
  })}`;

  // Only add spotIds query param if there are any passed in.
  if (spotIds.length > 0) {
    url = `${url}&${createParamString({ spotIds })}`;
  }

  return baseFetch(url, options);
};

export default fetchForecastOutlook;
