import React, { createContext, useMemo } from 'react';
import Script from 'next/script';
import { nrNoticeError } from '@surfline/web-common';
import { VIDEO_JS_URLS } from 'common/constants';
import useVideoJS from 'hooks/useVideoJS';

export const VideoJSContext = createContext({ videoJsLoaded: false });

interface VideoJSContextProviderProps {
  children: React.ReactNode;
  location?: string;
}

export const VideoJSContextProvider = ({
  children,
  location = 'video.js player',
}: VideoJSContextProviderProps) => {
  const { videoJsLoaded, setVideoJsLoaded, setVideoJsError } = useVideoJS();

  const contextValue = useMemo(() => ({ videoJsLoaded }), [videoJsLoaded]);

  return (
    <VideoJSContext.Provider value={contextValue}>
      <Script
        id="video-js"
        data-testid="video-js-script"
        strategy="afterInteractive"
        src={VIDEO_JS_URLS.js.src}
        onLoad={() => setVideoJsLoaded(true)}
        onError={(e) => {
          setVideoJsError('Failed to load Video.js');
          nrNoticeError(e, { location });
        }}
      />
      {children}
    </VideoJSContext.Provider>
  );
};
