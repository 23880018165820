import { Typography } from '@mui/material';
import { AddIcon } from '@wavetrak/theme';
import React from 'react';
import styles from './MissingFavourites.module.scss';

interface Props {
  title?: string;
  boldText: string;
  normalText: string;
}

const FAVOURITES_HREF = `/setup/favorite-surf-spots`;

const MissingFavourites: React.FC<Props> = ({ title, boldText, normalText }) => (
  <div className={styles.missingFavouritesContent} data-testid="missing-favourites">
    {title && (
      <Typography variant="title1" className={styles.title}>
        {title}
      </Typography>
    )}
    <div className={styles.textBackground}>
      <div className={styles.textBox}>
        <Typography variant="title3" className={styles.boldText}>
          {boldText}
        </Typography>
        <Typography variant="body1" className={styles.normalText}>
          {normalText}
        </Typography>
        <a href={FAVOURITES_HREF}>
          <AddIcon className={styles.button} />
        </a>
      </div>
    </div>
  </div>
);

export default MissingFavourites;
