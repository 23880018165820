import { Theme, ThemeProvider, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

import styles from '../../containers/Favorites/Favorites.module.scss';

type FavoriteRegionFiltersPropTypes = {
  favoriteRegions: Array<string>;
  selectedRegions: Array<string>;
  handleRegionSelected: (event: React.MouseEvent<HTMLElement>, newRegions: string[]) => void;
  noFavorites: boolean;
  theme: Theme;
};

const FavoriteRegionFilters = ({
  favoriteRegions,
  selectedRegions,
  handleRegionSelected,
  noFavorites,
  theme,
}: FavoriteRegionFiltersPropTypes) => (
  <ThemeProvider theme={theme}>
    <div
      className={styles.regionsSelectorScrollContainer}
      data-testid="regions-selector-scroll-container"
    >
      <div className={styles.regionSelectorContainer}>
        <ToggleButtonGroup
          value={selectedRegions}
          onChange={handleRegionSelected}
          className={styles.regionSelector}
        >
          {!noFavorites &&
            favoriteRegions?.map((region) => (
              <ToggleButton
                disableRipple
                key={region}
                value={region}
                data-testid="favorites-region-selector-toggles"
              >
                <Typography variant="body2">{region}</Typography>
              </ToggleButton>
            ))}
        </ToggleButtonGroup>
      </div>
    </div>
  </ThemeProvider>
);

export default FavoriteRegionFilters;
