import { useMemo } from 'react';
import type { DaySelectorOverview } from 'types/daySelector';
import createDateAtMidDay from 'utils/createDateAtMidDay';

const fillDatesToMin = (dates: string[], minLength: number): (Date | null)[] => {
  if (dates.length === 0) {
    return Array.from(Array(minLength)).map(() => null);
  }

  const sortedDates = dates
    .map((dateString) => createDateAtMidDay(dateString))
    .sort((a, b) => a.getTime() - b.getTime());

  if (sortedDates.length >= minLength) {
    return sortedDates;
  }

  const numberOfDatesToAppend = minLength - sortedDates.length;

  const filledDates = Array.from(Array(numberOfDatesToAppend)).map((_, index: number) => {
    const date = createDateAtMidDay(dates[dates.length - 1]);
    date?.setDate(date.getDate() + index + 1);
    return date;
  });

  return sortedDates.concat(filledDates);
};

/*
This hook does the following:
- searches the data for all possible dates and creates a unique array from this
- converts the date strings to date objects
- sorts these dates into order
- fills the array with dates until the array has a length of `minForecastDates`

- also returns list of unique dates
- also finds the array with the longest initial length and returns how long it is
*/
const useForecastDates = (forecasts: DaySelectorOverview[], minForecastDates: number = 16) => {
  const uniqueDates = useMemo(() => {
    const allDates = forecasts
      ?.map((forecast) => forecast.days)
      .flat()
      .map((day) => day?.date)
      .filter((date): date is string => !!date);

    return [...new Set(allDates)];
  }, [forecasts]);

  const largestForecastLengthDays = useMemo(() => {
    const allDatesLengths = forecasts
      ?.map((forecast) => forecast.days?.length)
      .filter((daysLength): daysLength is number => !!daysLength)
      .sort((a, b) => b - a);

    return allDatesLengths[0];
  }, [forecasts]);

  const forecastDates = fillDatesToMin(uniqueDates, minForecastDates);

  return {
    forecastDates,
    uniqueDates,
    largestForecastLengthDays: largestForecastLengthDays || 0,
  };
};

export default useForecastDates;
