import React from 'react';
import Image from 'next/image';
import { PlayIcon } from 'components/Icons';
import styles from './CamStill.module.scss';

interface Props {
  imageUrl: string;
}

const CamStill: React.FC<Props> = ({ imageUrl }) => (
  <div className={styles.camStill} data-testid="cam-still">
    <Image
      layout="fill"
      loading="lazy"
      objectFit="cover"
      objectPosition="center"
      src={imageUrl}
      alt="spot camera still"
      unoptimized
    />
    <div className={styles.playIconContainer}>
      <PlayIcon className={styles.videoIcon} />
    </div>
  </div>
);

export default CamStill;
