import React from 'react';

interface Props {
  className?: string;
}

export const EditIcon = ({ className }: Props) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.16667 10.8333L11.75 1.25001C12.5621 0.437851 13.4381 0.438102 14.25 1.25001C14.25 1.25001 15.9381 2.9381 16.75 3.75C17.5619 4.56191 17.5621 5.43785 16.75 6.25L7.16666 15.8333L1.30435 17.6976C1.0203 17.7865 0.710413 17.7104 0.499998 17.5C0.289584 17.2896 0.213452 16.9797 0.302391 16.6956L2.16667 10.8333ZM3.41666 12.0833L5.91666 14.5833L2.16667 15.8333L3.41666 12.0833ZM6.75 13.75L13.8333 6.66667L11.3333 4.16667L4.25 11.25L6.75 13.75ZM12.2159 3.2841L14.7159 5.7841L15.2795 5.22054C15.4669 5.03312 15.4669 4.7293 15.2796 4.54194L13.4581 2.72044C13.2707 2.53308 12.9669 2.53312 12.7795 2.72055L12.2159 3.2841Z"
      fill="#171717"
    />
  </svg>
);

export default EditIcon;
