import React, { useEffect } from 'react';
import classNames from 'classnames/bind';

import type { Height, SurfHeight } from 'types/units';
import type { SurfDataPoints } from 'types/surf';
import type { SwellDataPoints } from 'types/swell';
import useSetupCurveGraph from '../../hooks/useSetupCurveGraph';

import styles from './SurfCurveChart.module.scss';

const cx = classNames.bind(styles);
interface Props {
  chartIdPrefix?: string;
  data: SurfDataPoints | SwellDataPoints;
  id: string;
  isCustomForecast?: boolean;
  units: Height | SurfHeight;
}

const SurfCurveChart: React.FC<Props> = ({ chartIdPrefix, data, id, isCustomForecast, units }) => {
  const chartId = `${chartIdPrefix}-surf-curve-chart-${id}`;

  const { setupCurveGraph } = useSetupCurveGraph({
    chartId,
    data,
    id,
    isCustomForecast,
    units,
  });

  useEffect(() => {
    setupCurveGraph();
  }, [setupCurveGraph]);

  return (
    <div className={cx(['surf-curve-chart-wrapper', styles.surfCurveChartWrapper])} id={chartId}>
      <div className={cx(['surf-curve-chart-content', styles.surfCurveChart])} />
    </div>
  );
};

export default SurfCurveChart;
