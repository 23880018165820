import { Button, Typography } from '@mui/material';
import { useMaxWidthDesktopSmall } from 'hooks/useMediaQueries';
import cx from 'classnames';

import styles from './ForecastDayTablePaywall.module.scss';

interface Props {
  showSmallPaywallDesktop?: boolean;
  onClickCta?: () => void;
}

const ForecastDayTablePaywall: React.FC<Props> = ({
  showSmallPaywallDesktop = false,
  onClickCta,
}) => {
  const isMobileView = useMaxWidthDesktopSmall();

  const CTA_PATH = '/upgrade';

  const smallPaywall = isMobileView || showSmallPaywallDesktop;

  const titleText = smallPaywall ? `Get 16 day forecasts with Premium` : `Waves are on the way.`;

  const subText = isMobileView
    ? `Plan ahead by viewing surf forecasts up to 16 days out.`
    : `Plan ahead with 16 day forecasts.`;

  const onClickCtaHandler = () => {
    if (onClickCta) {
      onClickCta();
    }
    window.location.href = CTA_PATH;
  };

  const paywallClasses = cx({
    [styles.paywall]: true,
    [styles.showLargePaywallDesktop]: !showSmallPaywallDesktop,
  });

  return (
    <div className={paywallClasses} data-testid="forecast-day-table-paywall">
      <div className={styles.textWrap}>
        <Typography variant={smallPaywall ? 'subHeadline' : 'title2'} className={styles.titleText}>
          {titleText}
        </Typography>
        {!smallPaywall && (
          <Typography variant="body" className={styles.subText}>
            {subText}
          </Typography>
        )}
        <Button
          variant="primary"
          size={isMobileView ? 'small' : 'large'}
          className={cx('bold', styles.callToActionButton)}
          onClick={onClickCtaHandler}
          href={CTA_PATH}
          data-testid="forecast-day-table-paywall-cta"
        >
          Start free trial
        </Button>
      </div>
    </div>
  );
};

export default ForecastDayTablePaywall;
