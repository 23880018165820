import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { slugify, trackEvent } from '@surfline/web-common';
import { useRouter } from 'next/router';

import { UserFavorites } from 'types/userFavorites';

import SpotPreviewCard from 'components/SpotPreviewCard';
import { spotPreviewCardTransformer } from 'utils/transformers';
import { Units } from 'types/units';
import { useMaxWidthTablet, useMaxWidthTabletLarge } from 'hooks/useMediaQueries';
import useInfiniteScroll from './useInfinateScroll';

interface Props {
  selectedFavorites: UserFavorites | undefined;
  loading: boolean;
  error: string | undefined;
  units: Units;
}
const FavoritesLive: React.FC<Props> = ({ selectedFavorites, units }) => {
  const isMobile = useMaxWidthTablet();
  const isTabletLg = useMaxWidthTabletLarge();

  const calculateGridRepeat = () => {
    if (isMobile) {
      return '1';
    }
    if (isTabletLg) {
      return '2';
    }
    return '3';
  };

  const { numberToRender, lastElementRef } = useInfiniteScroll(selectedFavorites);

  const router = useRouter();

  const onClickHandler = useCallback(
    (title, cardIndex, id) => {
      trackEvent('Carousel Clicked', {
        carouselItem: title,
        carouselName: 'Favorites Modal',
        category: 'kbyg',
        path: router.asPath,
        positionNumber: cardIndex,
        title: 'Favorites',
      });
      window.location.href = `/surf-report/${slugify(title)}/${id}`;
    },
    [router.asPath],
  );
  return (
    <Box data-testid="favorites-live-fullscreen">
      {selectedFavorites && (
        <Box display="grid" gridTemplateColumns={`repeat(${calculateGridRepeat()}, 1fr)`} gap={2}>
          {selectedFavorites.slice(0, numberToRender).map((fav, index) => (
            <Box key={fav.name}>
              <div ref={index + 1 === numberToRender ? lastElementRef : undefined}>
                <SpotPreviewCard
                  {...spotPreviewCardTransformer(fav, units)}
                  hideThumbnailOnMobile
                  cardIndex={index}
                  isMobileView={isMobile}
                  onClickHandler={() => onClickHandler(fav.name, index, fav._id)}
                />
              </div>
            </Box>
          ))}
          {numberToRender === 0 && <div ref={lastElementRef} />}
        </Box>
      )}
    </Box>
  );
};

export default FavoritesLive;
