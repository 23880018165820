import { useCallback } from 'react';
import { useUnmount } from 'react-use';
import { area, curveMonotoneX } from 'd3-shape';
import { scaleTime } from 'd3-scale';
import { select, Selection } from 'd3-selection';
import { extent } from 'd3-array';

import type { SurfDataPoints, SurfDataPoint } from 'types/surf';
import type { SwellDataPoint, SwellDataPoints } from 'types/swell';
import type { Height, SurfHeight } from 'types/units';
import { scaleWaveHeight } from 'utils/scaleWaveHeight';

const scaleData = (
  data: SurfDataPoint[] | SwellDataPoint[],
  units: Height | SurfHeight,
  graphHeight: number,
  isCustomForecast: boolean = false,
) =>
  data.map((dataPoint: SurfDataPoint | SwellDataPoint) => {
    const swellHeight = 'height' in dataPoint ? dataPoint.height : 0;
    const surfHeight = 'max' in dataPoint ? dataPoint.max : 0;
    const value = isCustomForecast
      ? scaleWaveHeight(swellHeight, units, graphHeight)
      : scaleWaveHeight(surfHeight, units, graphHeight);
    return {
      value,
      timestamp: new Date(dataPoint.timestamp * 1000),
    };
  });

type Props = {
  chartId: string;
  data: SurfDataPoints | SwellDataPoints;
  id: string;
  isCustomForecast?: boolean;
  smoothingStrength?: number;
  units: Height | SurfHeight;
};

const useSetupCurveGraph = ({
  chartId,
  data,
  id,
  isCustomForecast,
  smoothingStrength,
  units,
}: Props) => {
  const setupCurveGraph = useCallback(() => {
    const graphContainer = select(`.surf-chart-container-${id}`).node() as HTMLDivElement;

    const chartWidth = graphContainer?.getBoundingClientRect()?.width || 0;
    const chartHeight = graphContainer?.getBoundingClientRect()?.height || 0;
    // Set smoothing to 6 so we use Midnight, 6am, Noon, 6pm as data points
    const smoothing = smoothingStrength || 6;

    let max = 0;

    const scaledData = scaleData(data, units, chartHeight, isCustomForecast);

    const graphData = scaledData
      .map((dataPoint, dayNumber) => {
        // Cuts down on number of data points to make curve smoother
        if (dayNumber % smoothing !== 0) return null;

        if (dataPoint.value > max) {
          max = dataPoint.value;
        }

        return {
          timestamp: dataPoint.timestamp,
          value: dataPoint.value,
        };
      })
      .filter((dataPoint) => !!dataPoint);

    const paddedMinYDomain = chartHeight;
    const xAxis = scaleTime()
      .range([0, chartWidth])
      .domain(extent(graphData, (d) => d?.timestamp) as any);

    const canvas = select(`#${chartId} .surf-curve-chart-content`);
    let svg = select(`#${chartId} .surf-curve-chart-svg`) as Selection<
      SVGSVGElement,
      unknown,
      HTMLElement,
      any
    >;

    let path = select(`#${chartId} .surf-curve-chart-svg path`) as Selection<
      SVGPathElement,
      unknown,
      HTMLElement,
      any
    >;

    const swellArea = area()
      .x((d: any) => xAxis(d.timestamp))
      .y0(paddedMinYDomain)
      .y1((d: any) => paddedMinYDomain - d.value)
      .curve(curveMonotoneX) as any;

    if (!svg.node()) {
      svg = canvas
        .append('svg')
        .attr('class', 'surf-curve-chart-svg')
        .attr('data-testid', 'surf-curve-chart-svg');

      const defs = svg.append('defs');

      const gradient = defs
        .append('linearGradient')
        .attr('id', `${chartId}svgGradient`)
        .attr('x1', '0%')
        .attr('x2', '0%')
        .attr('y1', '0%')
        .attr('y2', '100%');

      gradient
        .append('stop')
        .attr('class', 'start')
        .attr('offset', '10%')
        .attr('stop-color', 'rgba(185, 211, 223, 0.8)')
        .attr('stop-opacity', 1);
      gradient
        .append('stop')
        .attr('class', 'end')
        .attr('offset', '110%')
        .attr('stop-color', 'rgba(185, 211, 223, 0)')
        .attr('stop-opacity', 1);

      path = svg
        .append('path')
        .attr('fill', `url(#${chartId}svgGradient)`)
        .attr('mix-blend-mode', 'multiply');
    }

    svg
      .attr('left', '0')
      .attr('right', '0')
      .attr('position', 'absolute')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', `0 0 ${chartWidth} ${chartHeight}`)
      .attr('preserveAspectRatio', 'none');

    path.attr('d', swellArea(graphData));
  }, [id, smoothingStrength, data, units, isCustomForecast, chartId]);

  useUnmount(() => {
    select(`#${chartId} .surf-curve-chart-svg`).remove();
  });

  return { setupCurveGraph };
};

export default useSetupCurveGraph;
