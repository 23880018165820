import React, { MouseEventHandler, useContext, useMemo } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import { isNumber, uniqueId } from 'lodash';
import { SpotPreviewCardData } from 'types/cards';
import { Camera } from 'types/camera';
import CurrentWaveHeightAndRating, { Size } from 'components/CurrentWaveHeightAndRating';
import SpotSatelliteWithPin from 'components/SpotSatelliteWithPin';
import { VideoJSContext } from 'contexts/VideoJSContext';
import CamStill from 'components/CamStill';
import { useUserPermissionStatus } from 'selectors/user';
import { SL_PREMIUM_PLUS } from 'common/treatments';
import { useTreatments } from 'utils/treatments';
import { CameraInsightsIcon } from 'components/Icons';
import { useMaxWidthMobileMedium } from 'hooks/useMediaQueries';
import { AutoplayOptions, PlayerSkins } from '../CamPlayerV2/constants';
import CamPlayerV2 from '../CamPlayerV2';
import conditionIsFlat from '../../utils/conditionIsFlat';

import styles from './SpotPreviewCard.module.scss';

export interface SpotPreviewCardProps extends SpotPreviewCardData {
  autoplay?: boolean;
  className?: string;
  hideThumbnailOnMobile?: boolean;
  stopStream?: boolean;
  onClickHandler?: MouseEventHandler;
  cardIndex: number;
  isMobileView: boolean;
  alwaysDisplaySmall?: boolean;
  sideThumbnail?: boolean;
  insightsCameraId?: null | string | boolean;
  showCamInsightsIconAtSmallWidth?: boolean;
}

const SpotPreviewCard: React.FC<SpotPreviewCardProps> = ({
  id,
  alwaysDisplaySmall,
  className,
  title,
  geo,
  currentWaveHeight,
  units,
  currentConditions,
  camera,
  hideThumbnailOnMobile = false,
  sideThumbnail = false,
  stopStream = false,
  isMobileView,
  cardIndex,
  onClickHandler = () => {},
  insightsCameraId = null,
  showCamInsightsIconAtSmallWidth = false,
}) => {
  const isFlat =
    isNumber(currentWaveHeight?.max) && units?.waveHeight
      ? conditionIsFlat(currentWaveHeight?.max, units?.waveHeight)
      : false;

  const hideCamPlayer = hideThumbnailOnMobile && isMobileView;

  const { hasPremiumCamPermissions } = useUserPermissionStatus();

  // memoize the uuid so it doesn't change every render;
  const randId = useMemo(() => uniqueId(), []);

  const playerProps = useMemo(
    () => ({
      autoplay: AutoplayOptions.AUTOPLAY_ENABLED,
      camera: camera as Camera,
      dontTrack: true,
      isFavorite: true,
      isPermitted: hasPremiumCamPermissions,
      latestRewind: false,
      location: 'Homepage Spot Preview Card',
      pauseWhenNotVisible: true,
      playerId: `${id}-${randId}`,
      playerSkin: PlayerSkins.THUMBNAIL,
      showOverlays: true,
      spotId: id,
      spotName: title,
      stopStream,
      segmentProperties: {
        location: 'Favorites Carousel',
        pageName: 'Home',
        spotId: id,
        spotName: title,
      },
    }),
    [camera, hasPremiumCamPermissions, id, randId, stopStream, title],
  );

  const { videoJsLoaded } = useContext(VideoJSContext);

  const treatments = useTreatments();

  const showCamInsightsIcon = useMemo(
    () => treatments[SL_PREMIUM_PLUS] === 'on' && insightsCameraId,
    [insightsCameraId, treatments],
  );

  const isMaxWidthMobileMedium = useMaxWidthMobileMedium();

  const camPlayerComponent = useMemo(() => {
    if (hideCamPlayer || sideThumbnail) return null;
    if (!camera) {
      return (
        <div className={styles.camPlayerPlaceholder}>
          <SpotSatelliteWithPin
            spotName={title}
            geo={geo}
            size={isMobileView ? 'small' : 'large'}
          />
        </div>
      );
    }
    if (hasPremiumCamPermissions && videoJsLoaded) {
      return (
        <div className={styles.camPlayerPlaceholder}>
          <CamPlayerV2 {...playerProps} />
        </div>
      );
    }
    return (
      <div className={styles.camPlayerPlaceholder}>
        <CamStill imageUrl={camera.stillUrlFull} />
      </div>
    );
  }, [
    camera,
    geo,
    hasPremiumCamPermissions,
    hideCamPlayer,
    isMobileView,
    playerProps,
    sideThumbnail,
    title,
    videoJsLoaded,
  ]);

  const sideThumbnailComponent = useMemo(() => {
    if (!sideThumbnail) return null;
    return (
      <div className={styles.sideThumbnail}>
        {!camera ? (
          <SpotSatelliteWithPin
            spotName={title}
            geo={geo}
            size={isMobileView ? 'small' : 'large'}
          />
        ) : (
          <CamStill imageUrl={camera.stillUrlFull} />
        )}
      </div>
    );
  }, [camera, geo, isMobileView, sideThumbnail, title]);

  return (
    <Card
      className={classNames(styles.card, className)}
      onClick={onClickHandler}
      data-testid={`spotPreviewCard-${cardIndex}`}
    >
      {camPlayerComponent}
      <div
        className={classNames(styles.camPlayerLabel, sideThumbnail && styles.sideThumbnailLabel)}
      >
        {sideThumbnailComponent}
        <Grid container>
          <Grid item mobile={11}>
            <Typography
              variant={isMobileView || alwaysDisplaySmall ? 'subHeadline' : 'title3'}
              component="h4"
              className={styles.spotName}
            >
              {title}
            </Typography>
            <CurrentWaveHeightAndRating
              alwaysDisplaySmall={alwaysDisplaySmall}
              size={Size.SMALL}
              conditions={currentConditions}
              isFlat={isFlat}
              units={units}
              waveHeight={currentWaveHeight}
            />
          </Grid>
          <Grid item mobile={1} className={styles.iconsWrapper}>
            {showCamInsightsIcon && !isMaxWidthMobileMedium && !showCamInsightsIconAtSmallWidth && (
              <CameraInsightsIcon />
            )}
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default SpotPreviewCard;
