import { Units } from '@surfline/web-common';

import baseFetch, { createParamString } from 'common/baseFetch';
import type {
  CustomForecastCurrentReponse,
  CustomForecastOverviewReponse,
  CustomForecastReponse,
} from 'types/customForecast';

interface BaseProps {
  units?: Units;
  noCache: boolean;
}

interface Props extends BaseProps {
  lat: number;
  lon: number;
}

interface CurrentProps extends Props {
  timestamp: number;
}

export function customForecastCurrent({ lat, lon, timestamp, units, noCache }: CurrentProps) {
  const headers = new Headers();
  if (noCache) {
    headers.set('Cache-Control', 'no-cache');
  }

  return baseFetch<CustomForecastCurrentReponse>(
    `/kbyg/conditions/current?${createParamString({
      lat,
      lon,
      timestamp,
      'units[temperature]': units?.temperature,
      'units[waveHeight]': units?.swellHeight,
      'units[windSpeed]': units?.windSpeed,
    })}`,
    {
      headers,
    },
  );
}

interface ForecastProps extends Props {
  days: number;
  intervalHours: number;
  start?: number;
}

export function customForecast({
  days,
  intervalHours,
  lat,
  lon,
  start,
  units,
  noCache,
}: ForecastProps) {
  const headers = new Headers();
  if (noCache) {
    headers.set('Cache-Control', 'no-cache');
  }

  return baseFetch<CustomForecastReponse>(
    `/kbyg/conditions/forecast?${createParamString({
      days,
      intervalHours,
      lat,
      lon,
      start,
      'units[temperature]': units?.temperature,
      'units[waveHeight]': units?.swellHeight,
      'units[windSpeed]': units?.windSpeed,
    })}`,
    {
      headers,
    },
  );
}

export function customForecastOverview({ units, noCache }: BaseProps) {
  const headers = new Headers();
  if (noCache) {
    headers.set('Cache-Control', 'no-cache');
  }

  return baseFetch<CustomForecastOverviewReponse>(
    `/kbyg/locations/forecasts-overview?${createParamString({
      'units[waveHeight]': units?.swellHeight,
    })}`,
    {
      headers,
    },
  );
}
