import type { UserType } from 'contexts/UserTypeContext';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { UserFavorites, UserFavoritesSWRResponse } from 'types/userFavorites';
import favoritesFetch, { getPopularSpots } from 'utils/favoritesFetch';

const useFetchUserFavorites = (
  userType?: UserType,
  userCountryCode?: string,
): UserFavoritesSWRResponse => {
  const endpoint = userType?.isAnonymous ? `/kbyg/spots/popular?` : `/kbyg/favorites?`;

  const {
    data,
    error,
    isLoading: loading,
  } = useSWR(
    endpoint,
    userType?.isAnonymous ? () => getPopularSpots(userCountryCode) : favoritesFetch,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryCount: 0,
    },
  );
  const [favorites, setFavorites] = useState<UserFavorites | undefined>(data?.data?.favorites);

  useEffect(() => {
    if (JSON.stringify(favorites) !== JSON.stringify(data?.data?.favorites)) {
      setFavorites(data?.data?.favorites);
    }
  }, [favorites, data, setFavorites]);

  const latestData = data && {
    data: {
      favorites: favorites as UserFavorites,
    },
    associated: data.associated,
  };

  return {
    data: latestData,
    loading,
    error,
  };
};

export default useFetchUserFavorites;
