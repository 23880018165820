import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { format } from 'date-fns';
import { isNil } from 'lodash';
import { Typography } from '@mui/material';
import { conditionClassModifier } from '@surfline/web-common';

import { DEFAULT_UNITS } from 'common/constants';
import { useMaxWidthTablet } from 'hooks/useMediaQueries';
import type { DaySelectorDay, RatingItem } from 'types/daySelector';
import conditionIsFlat from 'utils/conditionIsFlat';
import formatWaveHeight from 'utils/formatWaveHeight';

import styles from './ForecastDay.module.scss';

const cx = classNames.bind(styles);

const getConditionsClassName = (
  className: string,
  conditions: string,
  hasCoreForecastPermissions: boolean,
  paywallColors: boolean,
) =>
  cx({
    [className]: true,
    [`sl-conditions-color--${conditionClassModifier(conditions)}-background`]:
      hasCoreForecastPermissions || !paywallColors,
    [`${className}--paywall`]: !hasCoreForecastPermissions && paywallColors,
  });

const getDynamicStateClassName = (className: string, isHovered: boolean, isFocused: boolean) =>
  cx({
    [`${className}Hover`]: isHovered,
    [`${className}Focus`]: isFocused,
  });

interface Props {
  data: DaySelectorDay;
  dateFormat?: 'MDY' | 'DMY';
  handleClick: () => void;
  isCustomForecast?: boolean;
  hasCoreForecastPermissions?: boolean;
  paywallColors: boolean;
  units: { swellHeight?: string; waveHeight?: string };
}

const ForecastDay: React.FC<Props> = ({
  data,
  dateFormat = 'MDY',
  handleClick,
  isCustomForecast = false,
  hasCoreForecastPermissions = false,
  paywallColors = false,
  units,
}) => {
  const isMobileView = useMaxWidthTablet();
  const [isFocused, setFocused] = useState<boolean>(false);
  const [isHovered, setHovered] = useState<boolean>(false);

  const height = useMemo(
    () => (isCustomForecast ? data?.swell : data?.surf),
    [data?.surf, data?.swell, isCustomForecast],
  );

  const ratings = useMemo<Array<RatingItem> | null>(
    () => (data?.rating ? [data?.rating?.am, data?.rating?.midday, data?.rating?.pm] : null),
    [data?.rating],
  );

  const heightLabel = useMemo(() => {
    const variant = isMobileView ? 'subHeadline' : 'title3';

    if (isCustomForecast) {
      const swellHeight = height && 'height' in height ? height.height : null;
      const swellPeriod = height && 'period' in height ? height.period : null;
      const swellUnit = units?.swellHeight ?? DEFAULT_UNITS.swellHeight;
      return (
        <Typography variant={variant}>
          <span className={styles.heightLabelCustomForecast}>
            {swellHeight}
            <span className={styles.units}>{formatWaveHeight(swellUnit, true)}</span>
          </span>{' '}
          <span className={styles.heightLabelCustomForecast}>
            {swellPeriod}
            <span className={styles.units}>s</span>
          </span>
        </Typography>
      );
    }

    const surfMin = height && 'min' in height ? height.min : null;
    const surfMax = height && 'max' in height ? height.max : null;
    const surfPlus = height && 'plus' in height ? height.plus : null;
    const surfUnit = units?.waveHeight ?? DEFAULT_UNITS.waveHeight;

    if (!isCustomForecast && !isNil(surfMax) && conditionIsFlat(surfMax, surfUnit)) {
      return <Typography variant={variant}>Flat</Typography>;
    }

    return (
      <Typography variant={variant}>
        {surfMin}-{surfMax}
        <span className={styles.units}>{`${formatWaveHeight(surfUnit, true)}${
          surfPlus ? '+' : ''
        }`}</span>
      </Typography>
    );
  }, [height, isCustomForecast, isMobileView, units?.swellHeight, units?.waveHeight]);

  const getDisplayDate = useCallback(() => {
    if (!data?.date) return '';
    if (dateFormat === 'MDY') {
      return format(new Date(data.date), 'iii, M/d');
    }
    return format(new Date(data.date), 'iii, d/M');
  }, [data?.date, dateFormat]);

  const getAriaLabel = useCallback(() => {
    const displayDate = getDisplayDate();
    if (displayDate === '') return displayDate;
    if (isCustomForecast) {
      const swellHeight = height && 'height' in height ? height.height : null;
      const swellPeriod = height && 'period' in height ? height.period : null;
      const swellUnit = units?.swellHeight ?? DEFAULT_UNITS.swellHeight;
      return `${displayDate} will be ${swellHeight}${swellUnit} and ${swellPeriod}s period`;
    }
    const surfMin = height && 'min' in height ? height.min : null;
    const surfMax = height && 'max' in height ? height.max : null;
    const surfUnit = units?.waveHeight ?? DEFAULT_UNITS.waveHeight;
    const surfPlus = height && 'plus' in height ? height.plus : null;
    return `${displayDate} will be ${surfMin} to ${surfMax}${surfUnit}${surfPlus ? '+' : ''}`;
  }, [getDisplayDate, height, isCustomForecast, units]);

  const onClickHandler = useCallback(() => handleClick(), [handleClick]);
  const onMouseOverHandler = useCallback(() => setHovered(true), []);
  const onMouseLeaveHandler = useCallback(() => setHovered(false), []);
  const onFocusHandler = useCallback(() => setFocused(true), []);
  const onBlurHandler = useCallback(() => setFocused(false), []);
  const onScrollHandler = useCallback(() => setHovered(false), []);

  if (!data || !data.date) return <div className={styles.nodata} data-testid="no-data-received" />;

  return (
    <button
      data-testid="forecast-table-forecast-day"
      type="button"
      className={cx(
        'graph-day',
        styles.wrapper,
        getDynamicStateClassName('dynamicClass', isHovered, isFocused),
        {
          [styles.wrapperActiveHover]: isHovered,
        },
      )}
      onClick={onClickHandler}
      onMouseOver={onMouseOverHandler}
      onMouseLeave={onMouseLeaveHandler}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
      onScroll={onScrollHandler}
      aria-label={getAriaLabel()}
    >
      <div
        className={cx(styles.details, { [styles.customForecastDetails]: isCustomForecast })}
        data-testid="forecast-table-forecast-day-details"
      >
        {heightLabel}
      </div>
      <div className={styles.conditions}>
        {ratings
          ? ratings.map((ratingItem, i: number) =>
              ratingItem.key ? (
                <div
                  key={`${ratingItem.key}-${i + 1}`}
                  data-testid="forecast-table-forecast-day-bar"
                  className={getConditionsClassName(
                    'bar',
                    ratingItem.key,
                    hasCoreForecastPermissions,
                    paywallColors,
                  )}
                />
              ) : null,
            )
          : null}
      </div>
    </button>
  );
};

export default ForecastDay;
