import React, { useCallback, useMemo, useState, useRef } from 'react';
import { Box, Button, Snackbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import classNames from 'classnames';
import { Cookies } from 'react-cookie';
import { nrNoticeError } from '@surfline/web-common';
import { doUnfavoriteSpot, doFetchUserFavorites } from 'actions/user';
import TickWithBorderIcon from 'components/Icons/TickWithBorderIcon';
import { useAppDispatch } from 'stores/hooks';
import type { UserFavorite } from 'types/userFavorites';
import updateUserFavorites from './utils/requests';

import styles from './FavoritesForm.module.scss';

interface Props {
  userFavorites?: Array<UserFavorite>;
}

export const handleSort = (originalArray: any, dragItemIndex: number, dragToIndex: number) => {
  let newArray = [];
  const favoritesCopy = JSON.parse(JSON.stringify(originalArray));
  const copiedItem = favoritesCopy[dragItemIndex];
  favoritesCopy.splice(dragItemIndex, 1);

  newArray = [
    ...favoritesCopy.slice(0, dragToIndex),
    copiedItem,
    ...favoritesCopy.slice(dragToIndex),
  ];

  return newArray;
};

const FavoritesForm: React.FC<Props> = ({ userFavorites }) => {
  const [favorites, setFavorites] = useState([...(userFavorites ?? [])]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [updateDidError, setUpdateDidError] = useState(false);
  const [dragFavorite, setDragFavorite] = useState<number | null>(null);
  const [draggedOverFavorite, setDraggedOverFavorite] = useState<number | null>(null);
  const firstItem = useRef<HTMLDivElement | null>(null);

  const DISPLAY_SNACKBAR_TIMEOUT = 3000;

  const cookie = new Cookies();
  const accessToken = cookie.get('access_token');

  const dispatch = useAppDispatch();

  const handleDragEnd = useCallback(async () => {
    setIsSaving(true);
    const sortedFavorites = handleSort(
      favorites,
      Number(dragFavorite),
      Number(draggedOverFavorite),
    );
    setDragFavorite(null);
    setDraggedOverFavorite(null);
    setShowSnackbar(true);
    setIsSaving(true);
    try {
      await updateUserFavorites(accessToken, sortedFavorites);
      setSnackbarMessage('Your favorites have been updated');
      setIsSaving(false);
      setUpdateDidError(false);
      setFavorites(sortedFavorites);
      dispatch(doFetchUserFavorites());
    } catch (error: any) {
      setSnackbarMessage('There was an error updating your favorites');
      setIsSaving(false);
      setUpdateDidError(true);
      nrNoticeError(error, {});
    }
    setTimeout(() => {
      setShowSnackbar(false);
      setIsSaving(false);
    }, 3000);
  }, [accessToken, dispatch, dragFavorite, draggedOverFavorite, favorites]);

  const handleRemove = useCallback(
    async (index: number) => {
      const spotId = favorites[index]._id;
      const spotName = favorites[index].name;
      setIsSaving(true);
      setShowSnackbar(true);
      const result = await dispatch(doUnfavoriteSpot(spotId));
      if (result.type === 'UNFAVORITE_SPOT/fulfilled') {
        const newFavorites = [...favorites];
        newFavorites.splice(index, 1);
        setFavorites(newFavorites);
        setSnackbarMessage(`${spotName} has been removed`);
        setIsSaving(false);
      } else {
        setSnackbarMessage(`There was an error removing ${spotName}`);
        setIsSaving(false);
        setUpdateDidError(true);
      }
      setTimeout(() => {
        setShowSnackbar(false);
      }, DISPLAY_SNACKBAR_TIMEOUT);
    },
    [dispatch, favorites],
  );

  const favoritesList = useMemo(
    () =>
      favorites.map((favorite, index) => (
        <Box
          ref={index === 0 ? firstItem : null}
          key={favorite._id}
          className={classNames({
            [styles.item]: true,
            [styles.isDragging]: index === dragFavorite,
            [styles.draggingOverAbove]:
              index === draggedOverFavorite && dragFavorite !== null && index < dragFavorite,
            [styles.draggingOverBelow]:
              index === draggedOverFavorite && dragFavorite !== null && index > dragFavorite,
            [styles.saving]: isSaving,
          })}
          draggable={!isSaving}
          onDragStart={() => {
            setDragFavorite(index);
          }}
          onDragEnter={() => {
            setDraggedOverFavorite(index);
          }}
          onDragEnd={handleDragEnd}
          onDragOver={(e) => e.preventDefault()}
          onTouchStart={() => {
            setDragFavorite(index);
          }}
          onTouchMove={(e) => {
            let yPosition = 203;
            let height = 71;
            if (firstItem.current) {
              yPosition = firstItem.current.getBoundingClientRect().top;
              const style = window.getComputedStyle(firstItem.current);
              height =
                firstItem.current.getBoundingClientRect().height + parseInt(style.marginBottom, 10);
            }
            setDraggedOverFavorite(Math.floor((e.targetTouches[0].clientY - yPosition) / height));
          }}
          onTouchEnd={handleDragEnd}
        >
          <Box className={styles.content}>
            <Typography variant="footnote" className={styles.spotName}>
              {favorite.name}
            </Typography>
            <Box className={styles.actions}>
              <Button onClick={() => handleRemove(index)} className={styles.remove}>
                <Typography variant="callout1">Remove</Typography>
              </Button>
              <MenuIcon className={styles.menuIcon} />
            </Box>
          </Box>
        </Box>
      )),
    [dragFavorite, draggedOverFavorite, favorites, handleDragEnd, handleRemove, isSaving],
  );

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.container}>
        {favoritesList}
        <Snackbar open={showSnackbar}>
          <Box
            className={classNames({ [styles.snackbarContainer]: true, [styles.saving]: isSaving })}
          >
            <Box className={styles.snackbarContent}>
              <Typography>{isSaving ? 'Saving...' : snackbarMessage}</Typography>
              {!isSaving && !updateDidError && <TickWithBorderIcon className={styles.tickIcon} />}
            </Box>
          </Box>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default FavoritesForm;
