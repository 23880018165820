import React, { createContext, useMemo } from 'react';

export const PageContext = createContext({ pageName: '', pageId: '' });

interface PageContextProviderProps {
  pageName: string;
  children: React.ReactNode;
}

export const PageContextProvider = ({ children, pageName }: PageContextProviderProps) => {
  const pageId = useMemo(() => pageName.replace(/ /g, ''), [pageName]);

  const contextValue = useMemo(() => ({ pageName, pageId }), [pageName]);

  return <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>;
};
