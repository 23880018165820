import React from 'react';

const TickWithBorderIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_746_31086)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5 60C47.0685 60 60.5 46.5685 60.5 30C60.5 13.4315 47.0685 0 30.5 0C13.9315 0 0.5 13.4315 0.5 30C0.5 46.5685 13.9315 60 30.5 60ZM30.5 57C45.4117 57 57.5 44.9117 57.5 30C57.5 15.0883 45.4117 3 30.5 3C15.5883 3 3.5 15.0883 3.5 30C3.5 44.9117 15.5883 57 30.5 57ZM42.7374 23.7374C43.4209 23.054 43.4209 21.946 42.7374 21.2626C42.054 20.5791 40.946 20.5791 40.2626 21.2626L26.5 35.0251L21.7374 30.2626C21.054 29.5791 19.946 29.5791 19.2626 30.2626C18.5791 30.946 18.5791 32.054 19.2626 32.7374L24.909 38.3839C25.7877 39.2626 27.2123 39.2626 28.091 38.3839L42.7374 23.7374Z"
        fill="#171717"
      />
    </g>
    <defs>
      <clipPath id="clip0_746_31086">
        <rect width="60" height="60" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default TickWithBorderIcon;
