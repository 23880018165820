import config from 'config';
import axios, { AxiosResponse } from 'axios';

const updateUserFavorites = async (
  accessToken: string,
  spots: Array<any>,
): Promise<AxiosResponse> => {
  const spotIds: Array<string> = [];
  spots.forEach((spot) => {
    spotIds.push(spot._id);
  });
  return axios({
    method: 'put',
    url: `${config.servicesURL}/favorites/?accesstoken=${accessToken}`,
    data: {
      type: 'spots',
      spotIds,
    },
  });
};

export default updateUserFavorites;
