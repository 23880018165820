import { GeoLocation } from 'types/locationView';
import config from 'config';

/**
 * Map configuration
 * https://docs.maptiler.com/cloud/api/static-maps/
 * https://cloud.maptiler.com/maps/hybrid/
 */

const getMapImageSrc = ({
  mapZoom,
  mapWidth,
  mapHeight,
  geo,
  mapID = 'hybrid',
}: {
  mapZoom: number;
  mapWidth: number;
  mapHeight: number;
  geo?: Partial<GeoLocation>;
  mapID?: string;
}) =>
  `https://api.maptiler.com/maps/${mapID}/static/${geo?.lon},${geo?.lat},${mapZoom}/${mapWidth}x${mapHeight}.png?key=${config.mapTiler.key}&attribution=0`;

export default getMapImageSrc;
