import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Box, Button, createTheme, Tab, Tabs, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { NextRouter, withRouter } from 'next/router';
import { useContextualRouting } from 'next-use-contextual-routing';
import {
  getUserDetails,
  getUserFavorites,
  trackEvent,
  trackNavigatedToPage,
} from '@surfline/web-common';
import { SL_WEB_CUSTOM_FORECASTS } from 'common/treatments';
import ControlBarScrollButtons from 'components/ControlBarScrollButtons';
import FavoritesCustomForecast from 'components/FavoritesCustomForecast';
import FavoritesForecast from 'components/FavoritesForecast';
import FavoritesLive from 'components/FavoritesLive';
import FavoriteRegionFilters from 'components/FavoriteRegionFilters';
import { EditIcon } from 'components/Icons/EditIcon';
import TickWithBorderIcon from 'components/Icons/TickWithBorderIcon';
import MissingFavourites from 'components/MissingFavourites';
import { ForecastOutlookContextProvider } from 'contexts/ForecastOutlookContext';
import { PageContext } from 'contexts/PageContext';
import { UserTypeContext } from 'contexts/UserTypeContext';
import useConditions from 'hooks/useConditions';
import useFetchUserFavorites from 'hooks/useFetchUserFavorites';
import { useMaxWidthTablet } from 'hooks/useMediaQueries';
import { useUserPermissionStatus, useUserUnits } from 'selectors/user';
import type { SpotReportData } from 'types/spot';
import type { Units } from 'types/units';
import type { UserFavorites, UserFavoritesSWRResponse } from 'types/userFavorites';
import { useTreatments } from 'utils/treatments';
import MetaTags from 'components/MetaTags';
import FavoritesForm from 'components/FavoritesForm';
import type { ButtonVariantType } from 'types/mui';

import { DEFAULT_UNITS } from 'common/constants';
import styles from './Favorites.module.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface FavoritesHeaderProps {
  isEditingFavorites: boolean;
  showEditButton: boolean;
  toggleIsEditing: () => void;
  userHasFavorites: boolean;
}

const defaultMissingArgs = {
  boldText: 'Looks a little empty in here.',
  normalText: 'Add some favorite spots to compare live conditions and forecasts.',
};

// @ts-ignore
const theme = createTheme({
  typography: {
    fontFamily: 'Linear Sans',
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'unset',
          color: '#424242',
          margin: '5px',
          padding: '7px 16px 8px 16px',
          gap: '10px',
          border: '1px solid #E4E7ED',
          height: '32px',
          '&.MuiToggleButtonGroup-grouped': {
            '&:first-of-type': {
              margin: '5px 5px 5px 0px',
            },
            '&:not(:first-of-type)': {
              border: '1px solid #E4E7ED',
              borderRadius: '90px',
            },
            '&:not(:last-of-type)': {
              borderRadius: '90px',
            },
            '&:hover': {
              border: '1px solid #171717',
              backgroundColor: '#FFFFFF',
            },
            '&.Mui-selected': {
              border: '1px solid #171717',
              backgroundColor: '#171717',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#171717',
                color: '#FFFFFF',
                border: '1px solid #171717',
                borderLeft: '1px solid #171717',
              },
              '&:first-of-type': {
                margin: '5px 5px 5px 0px',
              },
              '&:not(:first-of-type)': {
                border: '1px solid #171717',
                borderRadius: '90px',
              },
            },
          },
        },
      },
    },
  },
});

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const FavoritesHeader = (props: FavoritesHeaderProps) => {
  const { isEditingFavorites, showEditButton = true, toggleIsEditing, userHasFavorites } = props;
  const isMobile = useMaxWidthTablet();
  let buttonText = isMobile ? 'Edit' : 'Edit Favorites';
  buttonText = isEditingFavorites ? 'Done' : buttonText;
  let buttonVariant: ButtonVariantType = isMobile ? 'text' : 'secondary';
  buttonVariant = isEditingFavorites ? 'primary' : buttonVariant;
  return (
    <div className={styles.header}>
      <Typography variant="h1">Favorites</Typography>
      {userHasFavorites && showEditButton && (
        <div
          className={classNames({
            [styles.headerButtons]: true,
            [styles.isEditingFavorites]: isEditingFavorites,
          })}
        >
          <Button variant={buttonVariant} className={styles.editButton} onClick={toggleIsEditing}>
            {!isMobile && isEditingFavorites && (
              <TickWithBorderIcon className={styles.editingIcon} />
            )}
            {!isMobile && !isEditingFavorites && <EditIcon className={styles.editIcon} />}
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};

const getRegionsBadgesFromFavorites = (favorites: UserFavorites | null): Array<string> => {
  const regions = favorites?.map((fav: SpotReportData) => fav.subregionName);
  return ['All', ...new Set(regions)] as Array<string>;
};

const filterFavoritesByRegions = (
  favorites: UserFavorites | undefined,
  selectedRegions: Array<string>,
): UserFavorites | undefined => {
  if (selectedRegions.includes('All')) return favorites;
  return favorites?.filter((fav: SpotReportData) => selectedRegions.includes(fav.subregionName));
};
interface Props {
  router: NextRouter;
  showMissingFavsWithLessThan?: number;
  surfHeightUnit: string;
  userCountryCode?: string;
}

const Favorites: React.FC<Props> = ({
  router,
  showMissingFavsWithLessThan = 5,
  surfHeightUnit,
  userCountryCode,
}) => {
  const { query } = router;
  const { type } = query;
  const mobile = useMaxWidthTablet();
  const { pageName } = useContext(PageContext);
  const { userType } = useContext(UserTypeContext);
  const { hasCoreForecastPermissions } = useUserPermissionStatus();
  const { themeClassName } = useConditions();
  const treatments = useTreatments();
  const slTheme = useTheme();

  const { makeContextualHref } = useContextualRouting();

  const [favoriteRegions, setFavoriteRegions] = useState<Array<string>>([]);
  const [selectedRegions, setSelectedRegions] = useState<Array<string>>(['All']);

  const userFavorites = useFetchUserFavorites(
    userType,
    userCountryCode,
  ) as UserFavoritesSWRResponse;

  const favorites = useSelector(getUserFavorites, shallowEqual) as UserFavorites;
  const userDetails = useSelector(getUserDetails);
  const hasUserAccount = !!userDetails;
  const units = userFavorites.data?.associated.units;
  const userUnits = useUserUnits();
  const { loading, error } = userFavorites;
  const noFavorites = useMemo(() => (favorites ? favorites.length < 1 : false), [favorites]);

  const lessThanXFavorites = favorites && favorites.length < showMissingFavsWithLessThan;

  const [selectedFavorites, setSelectedFavorites] = useState<UserFavorites | undefined>(undefined);

  const tabs = useMemo(() => {
    const tabItems = [
      {
        label: 'Live',
        key: 'live',
      },
      {
        label: 'Forecast',
        key: 'forecast',
      },
    ];
    if (treatments[SL_WEB_CUSTOM_FORECASTS] === 'on') {
      tabItems.push({
        label: 'Custom Forecast',
        key: 'custom-forecast',
      });
    }
    return tabItems;
  }, [treatments]);

  const currentTabIndex = useMemo(() => {
    const index = tabs.findIndex(({ key }) => key === type);
    return index > -1 && type !== undefined ? index : 0;
  }, [tabs, type]);

  const [tab, setTab] = useState(currentTabIndex);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (favorites) {
      const favRegions = getRegionsBadgesFromFavorites(favorites);
      setFavoriteRegions(favRegions);
    }
  }, [favorites]);

  useEffect(() => {
    setSelectedFavorites(filterFavoritesByRegions(favorites, selectedRegions));
  }, [selectedRegions, favorites]);

  const handleRegionSelected = useCallback(
    (event: React.MouseEvent<HTMLElement>, newRegions: string[]) => {
      if (newRegions[newRegions.length - 1] === 'All' || newRegions.length === 0) {
        setSelectedRegions(['All']);
      } else if (newRegions[0] === 'All') {
        setSelectedRegions(newRegions.slice(1));
      } else {
        setSelectedRegions(newRegions);
      }
    },
    [],
  );

  const [isEditingFavorites, setIsEditingFavorites] = useState(false);

  const toggleIsEditing = useCallback(() => {
    setIsEditingFavorites((prev) => {
      if (prev === false) {
        trackNavigatedToPage('Edit Favorites', {});
      }
      return !prev;
    });
  }, []);

  const debouncedScrollHandler = useMemo(
    () =>
      debounce(
        (scrollDirection: string, action: string) => {
          trackEvent('Forecast Range Updated', {
            action,
            pageName,
            scrollDirection,
            uiElement: 'forecast outlook',
          });
        },
        1000,
        {
          leading: true,
          trailing: false,
        },
      ),
    [pageName],
  );

  const handleTabChange = useCallback(
    (_event: any, newType: number) => {
      const newQueryParam = tabs[newType].key;
      router.push(makeContextualHref({ type: newQueryParam }), `/favorites?type=${newQueryParam}`, {
        shallow: true,
      });
      setTab(newType);
    },
    [tabs, router, makeContextualHref],
  );

  const tabNav = useMemo(
    () => (
      <Tabs
        value={tab}
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="secondary"
        className={styles.tabLabels}
        data-testid="favorites-tabs"
      >
        {tabs.map(({ key, label }) => (
          <Tab key={key} label={label} className={styles.tab} disableFocusRipple disableRipple />
        ))}
      </Tabs>
    ),
    [handleTabChange, tab, tabs],
  );

  return (
    <Box className={classNames('sl-section-container', themeClassName)}>
      <MetaTags
        title="Surfline Favorite Surf Spots"
        description="Surfline Favorite Surf Spots"
        urlPath="/favorites"
      />
      <Box className={styles.favoritesHeader}>
        <FavoritesHeader
          isEditingFavorites={isEditingFavorites}
          showEditButton={currentTabIndex !== 2 && hasUserAccount}
          toggleIsEditing={toggleIsEditing}
          userHasFavorites={!noFavorites}
        />
        {!isEditingFavorites && tabNav}
      </Box>
      <Box className={styles.favoritesTabs}>
        {!isEditingFavorites ? (
          <>
            <div className={classNames({ [styles.headerWrap]: !mobile })}>
              {tabs[tab]?.key !== 'custom-forecast' && (
                <FavoriteRegionFilters
                  favoriteRegions={favoriteRegions}
                  selectedRegions={selectedRegions}
                  handleRegionSelected={handleRegionSelected}
                  noFavorites={noFavorites}
                  theme={theme}
                />
              )}
              {tabs[tab]?.key !== 'live' && scrollRef && (
                <ControlBarScrollButtons
                  shouldScroll={!noFavorites}
                  buttonColor={slTheme.palette.secondary.main}
                  sectionContainerClass
                  scrollRef={scrollRef.current}
                  debouncedScrollHandlers={{
                    left: (action: string) => debouncedScrollHandler('Left', action),
                    right: (action: string) => debouncedScrollHandler('Right', action),
                  }}
                />
              )}
            </div>
            <TabPanel value={tab} index={0}>
              <FavoritesLive
                selectedFavorites={selectedFavorites}
                loading={loading}
                error={error}
                units={units as Units}
              />
              {lessThanXFavorites && !error && (
                <MissingFavourites
                  boldText={defaultMissingArgs.boldText}
                  normalText={defaultMissingArgs.normalText}
                />
              )}
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <ForecastOutlookContextProvider
                hasCoreForecastPermissions={hasCoreForecastPermissions}
                surfHeightUnit={surfHeightUnit}
              >
                {!noFavorites && (
                  <FavoritesForecast
                    selectedFavorites={selectedFavorites as UserFavorites}
                    scrollRef={scrollRef}
                  />
                )}
              </ForecastOutlookContextProvider>
              {lessThanXFavorites && !error && (
                <MissingFavourites
                  boldText={defaultMissingArgs.boldText}
                  normalText={defaultMissingArgs.normalText}
                />
              )}
            </TabPanel>
            {treatments[SL_WEB_CUSTOM_FORECASTS] === 'on' && (
              <TabPanel value={tab} index={2}>
                <FavoritesCustomForecast
                  scrollRef={scrollRef}
                  units={(userUnits || DEFAULT_UNITS) as Units}
                />
              </TabPanel>
            )}
          </>
        ) : (
          <FavoritesForm userFavorites={favorites} />
        )}
      </Box>
    </Box>
  );
};

export default withRouter(Favorites);
