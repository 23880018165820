import { Typography } from '@mui/material';
import { useMaxWidthTablet } from 'hooks/useMediaQueries';

import styles from './ForecastDayHeading.module.scss';

interface Props {
  date: Date | null;
}

const ForecastDayHeading: React.FC<Props> = ({ date }) => {
  const isMobileView = useMaxWidthTablet();

  if (!date) {
    return <div className={styles.loading} />;
  }

  // the date is already in the spots timezone, but represented as UTC
  // so we need to display the UTC date - NOT the local date (users browser timezone)
  const engDayName = new Intl.DateTimeFormat('en', { weekday: 'short', timeZone: 'UTC' });
  const dayStr = engDayName.format(date);
  return (
    <div className={styles.dayHeading} data-testid="forecast-day-table-heading-day-text">
      <Typography variant="callout1" className={styles.day}>
        {isMobileView ? dayStr[0].toUpperCase() : dayStr}
      </Typography>
      <Typography variant="callout1" className={styles.date}>
        {date?.getUTCDate()}
      </Typography>
    </div>
  );
};

export default ForecastDayHeading;
