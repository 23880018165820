import { useCallback, useEffect, useState } from 'react';
// @ts-ignore
import useIntersectionObserver from './useIntersectionObserver';

const LOAD_COUNT = 6;

const useInfiniteScroll = (DataToRender: Array<any> | undefined) => {
  const [numberToRender, setNumberToRender] = useState<number>(0);

  const [lastElement, lastElementRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (DataToRender?.length) {
      setNumberToRender(LOAD_COUNT);
    }
  }, [setNumberToRender, DataToRender]);

  const increaseNumberToRender = useCallback(() => {
    if (DataToRender?.length) {
      setNumberToRender((current) => Math.min(DataToRender.length, current + LOAD_COUNT));
    }
  }, [setNumberToRender, DataToRender]);

  useIntersectionObserver(lastElement, increaseNumberToRender);

  return { numberToRender, lastElementRef };
};

export default useInfiniteScroll;
