import React from 'react';
import { isNil } from 'lodash';
import { Skeleton, Typography } from '@mui/material';

import WavetrakLink from 'components/WavetrakLink';
import { useMaxWidthTablet } from 'hooks/useMediaQueries';

import styles from './ForecastLabel.module.scss';

interface Props {
  subtitle?: string;
  title: string;
  url: string;
}

const ForecastLoading: React.FC = () => (
  <div className={styles.skeletonWrapper} data-testid="forecast-day-table-spot-label-loading">
    <Skeleton variant="rectangular" width="80%" className={styles.labelLoading} />
    <Skeleton variant="rectangular" width="50%" className={styles.labelLoading} />
  </div>
);

const ForecastLabel: React.FC<Props> = ({ subtitle, title, url }) => {
  const isMobileView = useMaxWidthTablet();
  if (isNil(title) || title === '') {
    return <ForecastLoading />;
  }
  return (
    /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
    <WavetrakLink href={url} className={styles.link}>
      <Typography
        className={styles.title}
        component="h4"
        variant={isMobileView || subtitle ? 'subHeadline' : 'title3'}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          className={styles.subtitle}
          component="p"
          variant={isMobileView ? 'caption' : 'callout2'}
        >
          {subtitle}
        </Typography>
      )}
    </WavetrakLink>
  );
};

export default ForecastLabel;
