import { useEffect, useState } from 'react';

const useIntersectionObserver = (element: HTMLDivElement | null, handler: () => void) => {
  const [currentObserver, setCurrentObserver] = useState<IntersectionObserver | null>(null);
  const [currentElement, setCurrentElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    let observer: IntersectionObserver | undefined;
    if (element && currentElement !== element) {
      currentObserver?.disconnect();
      observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            handler();
          }
        },
        { rootMargin: '100px' },
      );

      observer.observe(element as HTMLDivElement);

      setCurrentObserver(observer);
      setCurrentElement(element);
    }

    return () => {
      currentObserver?.disconnect();
    };
  }, [element, handler, setCurrentObserver, currentObserver, setCurrentElement, currentElement]);
};

export default useIntersectionObserver;
