import { round } from 'lodash';
import { z } from 'zod';

export const convertLatOrLonForDisplay = (latOrLon: number) => round(latOrLon, 2);

export const convertLatitudeToCardinal = (latitude: number) =>
  latitude && latitude < 0 ? 'ºS' : 'ºN';

export const convertLongitudeToCardinal = (longitude: number) =>
  longitude && longitude < 0 ? 'ºW' : 'ºE';

export const latLonUndefinedMessage = '--ºN / --ºE';

const invalidLatLonMessage = 'Please enter a valid latitude and longitude.';

export const LatLonSchema = z.object({
  lat: z
    .number()
    .min(-90, { message: invalidLatLonMessage })
    .max(90, { message: invalidLatLonMessage }),
  lon: z
    .number()
    .min(-180, { message: invalidLatLonMessage })
    .max(180, { message: invalidLatLonMessage }),
});
