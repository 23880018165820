import React, { useState } from 'react';
import cx from 'classnames';
import Image from 'next/image';
import { useTheme } from '@mui/material';
import { MapPinIcon } from '@wavetrak/theme';

import ErrorBoundary from 'components/ErrorBoundary';
import ErrorMessage from 'components/ErrorMessage';
import type { GeoLocation } from 'types/locationView';
import getMapImageSrc from 'utils/urls/mapTilerPath';

import styles from './SpotSatelliteWithPin.module.scss';

export interface SpotSatelliteWithPinProps {
  spotName: string;
  geo?: GeoLocation;
  isError?: boolean;
  size?: 'small' | 'large';
}

const checkValidCoordinates = (geo: GeoLocation | undefined): boolean => {
  if (!geo || !geo?.lat || !geo?.lon) return false;
  if (geo.lat > 90 || geo.lat < -90) return false;
  if (geo.lon > 180 || geo.lon < -180) return false;
  return true;
};

const mapImageSrc = (isLarge: boolean, geo?: Partial<GeoLocation>) =>
  getMapImageSrc({
    mapZoom: isLarge ? 13.5 : 13,
    mapWidth: isLarge ? 750 : 640,
    mapHeight: isLarge ? 500 : 400,
    geo,
  });

const SpotSatelliteWithPin: React.FC<SpotSatelliteWithPinProps> = ({
  isError,
  geo,
  spotName,
  size = 'small',
}) => {
  const theme = useTheme();
  const [imageError, setImageError] = useState(false);
  const isLarge = size === 'large';
  let showError = isError;

  if (!checkValidCoordinates(geo)) {
    showError = true;
  }

  return (
    <ErrorBoundary
      error={showError || imageError}
      render={() => (
        <ErrorMessage
          dataTestId="spot-satellite-map-error"
          className={cx(
            styles.map,
            isLarge && styles.mapLarge,
            !isLarge && styles.mapSmall,
            styles.mapError,
          )}
        />
      )}
    >
      <figure
        data-testid="spot-satellite-map-image"
        className={cx(styles.map, isLarge && styles.mapLarge, !isLarge && styles.mapSmall)}
      >
        <Image
          alt={`Satellite map of ${spotName || 'spot'}`}
          layout="fill"
          loading="lazy"
          objectFit="cover"
          objectPosition="center"
          onError={() => setImageError(true)}
          placeholder={undefined}
          src={mapImageSrc(isLarge, geo)}
          unoptimized
        />
        <div className={styles.pinContainer}>
          <MapPinIcon
            className={cx(styles.pin)}
            color={theme.palette.common.white}
            size={isLarge ? 28 : 24}
            aria-label={`Location pin for ${spotName} || spot`}
            data-testid="spot-satellite-pin"
          />
        </div>
      </figure>
    </ErrorBoundary>
  );
};

export default SpotSatelliteWithPin;
